import {useActionData, useLocation} from '@remix-run/react';
import {useDuxState, useViewability} from '@shopify/dux';
import {ValidatedForm, useIsSubmitting} from 'remix-validated-form';
import {useEffect, useState} from 'react';

import {useBrowserUrl} from '@/hooks/useBrowserUrl';
import {ApprovedCookie, FormSourceTypes, FormTypes, IconEnum} from '@/enums';
import {twMerge} from '@/stylesheets/twMerge';
import Icon from '@/components/base/elements/Icon/Icon';
import Button from '@/components/base/elements/Button/Button';
import FormButton from '@/components/base/elements/FormButton/FormButton';
import ShopifyLogo from '@/components/base/elements/Logo/ShopifyLogo';
import {
  ShopifyLogoColor,
  ShopifyLogoTheme,
} from '@/components/base/elements/Logo/types';
import {useTranslations} from '@/hooks/useTranslations';
import Typography from '@/components/base/elements/Typography/Typography';
import {useSiteData} from '@/hooks/useSiteData';
import {BaseInput as Input} from '@/components/shared/validatedForm/legacy/BaseInput';
import {Select} from '@/components/shared/validatedForm/legacy/Select';
import RadioGroup from '@/components/shared/RadioGroup/RadioGroup';
import {getCountriesList} from '@/components/plus/shared/forms/countries';
import {UNKNOWN} from '@/components/plus/shared/forms/annualRevenueRanges';
import {
  contactFormValidator as contactPlusFormValidator,
  contactBlogFormValidator,
} from '@/utils/validator/plus/contactFormValidator';
import {
  type HandleFormSubmitOptions,
  useFormTracking,
} from '@/hooks/useDuxTracking';
import {getCookie} from '@/utils/utils';
import useDataLayer from '@/hooks/useDataLayer';
import {useFlag} from '@/hooks/useFlag';
import {FLAG_PLUS_1800_HOLIDAY_AU, FLAG_PLUS_1800_HOLIDAY_US_CA} from '@/flags';
import useBookIt from '@/hooks/useBookIt';
import AnnualRevenueRangeSelect from '@/components/shared/LeadFormFields/AnnualRevenueRangeSelect';
import CheckIcon from '@/components/plus/section/ContactForm/CheckIcon';
import {useExperiment} from '@/hooks/useExperiment';
import {EXP_FREE_TRIAL_ON_THANK_YOU_PAGE_FOR_UNDER_5M_NEW} from '@/experiments';
import {RevenueValues} from '@/components/shared/BaseLeadForm/fields/constants';
import useSignup from '@/hooks/useSignup';
import useGlobalNav from '@/hooks/navigation/global';
import Link from '@/components/base/elements/Link/Link';
import {helpUrl} from '@/hooks/navigation/utils';
import {useFormatCurrency} from '@/utils/string/formatCurrency';
import {buttonStyles} from '@/components/base/elements/Button/styles';

interface ContactProps {
  wrapperClassName?: string;
  directMarketingCampaignId?: string;
  isPlus?: boolean;
}

const DRIFT_INTERACTION_ID_DEFAULT = '391505';
const DRIFT_INTERACTION_ID = new Map([
  ['de', '391514'],
  ['es-ES', '391516'],
  ['fr', '391517'],
  ['it', '391515'],
  ['ja', '391518'],
  ['zh-CN', '391519'],
]);

export default function Contact({
  wrapperClassName = '',
  directMarketingCampaignId,
  isPlus = true,
}: ContactProps) {
  const {t, localizePath} = useTranslations();
  const [viewableRef] = useViewability();
  const {site, canonicalUrl} = useSiteData();
  const dataLayer = useDataLayer();
  const [lastStatus, setLastStatus] = useState('');
  const [minGMV, setMinGMV] = useState(false);
  const [trackingData, setTrackingData] = useState({} as any);
  const location = useLocation();
  const currentURL = useBrowserUrl();

  const isSubmitting = useIsSubmitting('contact');
  const actionData = useActionData<any>();

  const trackSuccessEvent = useFormTracking(successTracker);
  const trackErrorEvent = useFormTracking(errorTracker);
  const {pageViewToken} = useDuxState();

  const useFreeTrialExperiment: any = useExperiment(
    EXP_FREE_TRIAL_ON_THANK_YOU_PAGE_FOR_UNDER_5M_NEW,
  );
  const isFreeTrialExperimentActive = useFreeTrialExperiment;
  const freeTrialExperimentVariant = useFreeTrialExperiment === 'treatment';
  const formatCurrency = useFormatCurrency();
  const currencyFormat = (value: number) =>
    formatCurrency(value, {minimumSignificantDigits: 1});

  // Free Trial Exp. Using hardcoded 2m - 5m since we don't have this setup yet in horton
  const freeTrialExperimentGMV = [
    RevenueValues.GETTING_STARTED,
    RevenueValues.BETWEEN_1_250K,
    RevenueValues.BETWEEN_250K_2M,
    `${currencyFormat(2000000)} - ${currencyFormat(5000000)}`,
  ];
  const [freeTrialExperiment, setFreeTrialExperiment] = useState(false);
  const [freeTrialExperimentSelectValue, setFreeTrialExperimentSelectValue] =
    useState('');

  const {signupUrl, signupName} = useSignup();
  const {signupText} = useGlobalNav();

  useBookIt(actionData, 'contact', '');

  useEffect(() => {
    const newTrackingData = {
      // eslint-disable-next-line camelcase
      form_type: FormTypes.Contact,
      page_view_token: pageViewToken,
      experiment_variation_id: dataLayer.experimentVariationId,
      primary_product: 'Plus',
      source: FormSourceTypes.Contact,
      user_token: getCookie(ApprovedCookie.MultiTrackToken),
      session_token: getCookie(ApprovedCookie.SessionToken),
      sign_up_page: canonicalUrl ?? '',
      utm_medium: encodeURIComponent(
        currentURL?.searchParams.get('utm_medium') || '',
      ),
      utm_campaign: encodeURIComponent(
        currentURL?.searchParams.get('utm_campaign') || '',
      ),
      utm_content: encodeURIComponent(
        currentURL?.searchParams.get('utm_content') || '',
      ),
      utm_source: encodeURIComponent(
        currentURL?.searchParams.get('utm_source') || '',
      ),
      utm_term: encodeURIComponent(
        currentURL?.searchParams.get('utm_term') || '',
      ),
      // eslint-disable-next-line camelcase
      shop_id: encodeURIComponent(
        currentURL?.searchParams.get('shop_id') || '',
      ),
      gclid: encodeURIComponent(currentURL?.searchParams.get('gclid') || ''),
      itterm: encodeURIComponent(currentURL?.searchParams.get('itterm') || ''),
      itcat: encodeURIComponent(currentURL?.searchParams.get('itcat') || ''),
      // eslint-disable-next-line camelcase
      direct_marketing_campaign_id: directMarketingCampaignId,
    };
    setTrackingData(newTrackingData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageViewToken]);

  useEffect(() => {
    if (actionData && lastStatus !== actionData.status) {
      setLastStatus(actionData.status);

      if (actionData.gmv !== UNKNOWN) {
        setMinGMV(true);
      }

      if (
        freeTrialExperimentVariant &&
        (freeTrialExperimentGMV.includes(actionData.gmv) ||
          freeTrialExperimentGMV.includes(freeTrialExperimentSelectValue))
      ) {
        setFreeTrialExperiment(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionData]);

  useEffect(() => {
    if (lastStatus === 'success') {
      trackSuccessEvent();
    } else if (lastStatus === 'error') {
      trackErrorEvent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastStatus]);

  function handleSubmit(): void {
    setLastStatus('');
  }

  function successTracker({trackers}: HandleFormSubmitOptions): void {
    const successEventTracking = {
      eventCategory: 'Plus_SignUp',
      eventAction: 'success',
      eventLabel: 'plus',
      event_non_interaction: 'false',
      event: 'form_success',
    };

    const eventData = {
      ...actionData.formHash,
      ...trackingData,
    };

    if (trackers?.gtm) {
      trackers.gtm(successEventTracking, eventData);
    }
  }

  function errorTracker({trackers}: HandleFormSubmitOptions): void {
    const errorEventTracking = {
      eventCategory: 'Plus_SignUp',
      eventAction: 'error',
      eventLabel: 'validation failed',
      event_non_interaction: 'false',
      event: 'form_error',
    };

    if (trackers?.gtm) {
      trackers.gtm(errorEventTracking, {});
    }
  }

  const driftInteractionId =
    DRIFT_INTERACTION_ID.get(site.locale) || DRIFT_INTERACTION_ID_DEFAULT;

  const plusHolidayUSCAFeature = useFlag(FLAG_PLUS_1800_HOLIDAY_US_CA);

  const plusHolidayAUFeature = useFlag(FLAG_PLUS_1800_HOLIDAY_AU);

  const callSupport = ['en-AU', 'en-CA', 'en'].includes(site.locale);

  const plusHolidayLogic = () => {
    const plusHolidayUSCA =
      plusHolidayUSCAFeature && ['en', 'en-CA'].includes(site.locale);

    const plusHolidayAU =
      plusHolidayAUFeature && ['en-AU'].includes(site.locale);

    if (plusHolidayUSCA || plusHolidayAU) {
      return false;
    }

    return true;
  };

  const infoList = t('plus:contactCustomizations.infoList');

  return (
    <section
      className={twMerge(
        'pb-16',
        wrapperClassName,
        lastStatus && 'min-h-screen',
      )}
      data-component-name="lead-contact-form"
      data-component-extra-form-type={trackingData.form_type}
      data-component-extra-source={trackingData.source}
      data-component-extra-primary-product={trackingData.primary_product}
      data-component-extra-sign-up-page={trackingData.sign_up_page}
      // wait for trackingData to settle
      ref={trackingData.form_type ? viewableRef : undefined}
    >
      {lastStatus === 'success' ? (
        <div className="text-center">
          {freeTrialExperiment && freeTrialExperimentVariant ? (
            <>
              <Typography as="h2" size="t1">
                {t('plus:contactCustomizations.freeTrialExp.heading')}
              </Typography>

              <Typography
                className="max-w-3xl mx-auto mt-4 mb-6"
                size="body-lg"
              >
                {t('plus:contactCustomizations.freeTrialExp.subheadHtml')}
              </Typography>

              <Button
                href={signupUrl}
                mode="dark"
                size="small"
                componentName={`plus-free-trial-exp-${signupName}`}
                className="py-2 px-5 text-base font-bold tracking-[-0.01em] whitespace-nowrap"
              >
                {signupText}
              </Button>

              <Typography className="mt-4 mb-6" size="body-sm">
                {t('plus:contactCustomizations.freeTrialExp.kicker')}
              </Typography>

              <div className="bg-plus-free-trial-exp-support max-w-5xl mx-auto rounded-2xl p-lg mt-2xl">
                <Typography size="t4">
                  {t('plus:contactCustomizations.freeTrialExp.support.heading')}
                </Typography>

                <Typography className="mt-4 mb-6">
                  {t(
                    'plus:contactCustomizations.freeTrialExp.support.subheadHtml',
                  )}
                </Typography>

                <Link
                  href={helpUrl(site)}
                  mode="dark"
                  componentName="plus-free-trial-exp-help-center-button"
                >
                  {t('plus:contactCustomizations.freeTrialExp.support.link')}
                </Link>
              </div>
            </>
          ) : (
            <>
              <h2 className="text-t1 font-bold mb-6">
                {t('plus:contactCustomizations.thanks.heading')}
              </h2>
              <p className="text-2xl">
                {t('plus:contactCustomizations.thanks.message')}
              </p>
              {callSupport && minGMV && plusHolidayLogic() && (
                <div className="max-w-2xl mx-auto mt-10">
                  <h2 className="text-t4 mb-4">
                    {t('plus:contactCustomizations.callUs.heading')}
                  </h2>
                  <p className="text-body-base mb-6">
                    {t('plus:contactCustomizations.callUs.message')}
                  </p>
                  <Button
                    href={
                      ['en-GB', 'en-AU'].includes(site.locale)
                        ? `tel:${t('plus:contactCustomizations.callUs.button')}`
                        : 'tel:+18446861946'
                    }
                    mode="dark"
                    componentName="contact-sales-call-us-button"
                  >
                    <div className="flex items-center">
                      <div className="mr-2">
                        <Icon icon={IconEnum.Phone} size={18} />
                      </div>
                      {t('plus:contactCustomizations.callUs.button')}
                    </div>
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        <div className="flex flex-col sm:flex-row gap-8 sm:gap-28">
          <div className="sm:basis-5/12">
            <a
              href={localizePath('/plus')}
              data-component-name="logo-contact-form"
            >
              <ShopifyLogo
                className="absolute left-0 -top-24"
                logoTheme={ShopifyLogoTheme.White}
                logoWithText={true}
                textColor={ShopifyLogoColor.White}
                height="36"
              />
            </a>
            <h1
              className={`${
                isPlus ? 'font-bold mb-4' : 'font-normal mb-8'
              } text-t1`}
            >
              {t('plus:contactCustomizations.heading')}
            </h1>
            <ul className="grid gap-y-2 mb-6 sm:mb-8">
              {infoList &&
                infoList.map((item: string, idx: number) => (
                  <li key={`${item}-${idx}`} className="flex">
                    <CheckIcon className="mr-2 mt-1 sm:mt-1.5 shrink-0" />
                    <Typography className="text-base sm:text-lg">
                      {item}
                    </Typography>
                  </li>
                ))}
            </ul>
            <Typography
              isRichtext
              className="[&_a]:!font-normal [&_a]:!no-underline [&_a:hover]:!underline text-body-lg lg:text-body-base link-plus"
            >
              {t('plus:contactCustomizations.preformMessageHtml')}
            </Typography>
          </div>
          <div className="sm:basis-7/12">
            <ValidatedForm
              validator={
                isPlus ? contactPlusFormValidator : contactBlogFormValidator
              }
              method="post"
              acceptCharset="UTF-8"
              id="contact"
              onSubmit={handleSubmit}
              noValidate
              action={currentURL?.pathname + location.search + location.hash}
            >
              <Input
                key="full_name"
                id="full_name"
                placeholder={t('plus:forms.contact.fullName.label')}
                label={t('plus:forms.contact.fullName.label')}
                type="text"
                errorClassName="bg-mandarin-50 shadow-mandarin-50"
                inputOnErrorClassName="shadow-mandarin-50"
              />
              <Input
                key="email"
                id="email"
                placeholder={t('plus:forms.contact.email.label')}
                label={t('plus:forms.contact.email.label')}
                type="email"
                errorClassName="bg-mandarin-50 shadow-mandarin-50"
                inputOnErrorClassName="shadow-mandarin-50"
              />
              <Input
                key="phone"
                id="phone"
                placeholder={t('plus:forms.contact.phone.label')}
                label={t('plus:forms.contact.phone.label')}
                type="tel"
                errorClassName="bg-mandarin-50 shadow-mandarin-50"
                inputOnErrorClassName="shadow-mandarin-50"
              />
              <Input
                key="company"
                id="company"
                placeholder={t('plus:forms.contact.company.label')}
                label={t('plus:forms.contact.company.label')}
                type="text"
                errorClassName="bg-mandarin-50 shadow-mandarin-50"
                inputOnErrorClassName="shadow-mandarin-50"
              />
              <Input
                key="website"
                id="website"
                placeholder={t('plus:forms.contact.website.label')}
                label={t('plus:forms.contact.website.label')}
                type="url"
                errorClassName="bg-mandarin-50 shadow-mandarin-50"
                inputOnErrorClassName="shadow-mandarin-50"
              />
              <div className="flex flex-col md:flex-row gap-x-gutter">
                <Select
                  key="country"
                  id="country"
                  name="contactCountry"
                  label={t('plus:forms.contact.country.label')}
                  labelClassName="text-xs text-gray-400"
                  className="text-md"
                  options={getCountriesList(site.locale)}
                  errorClassName="bg-mandarin-50 shadow-mandarin-50"
                  inputOnErrorClassName="shadow-mandarin-50"
                  divider
                />
                <AnnualRevenueRangeSelect
                  type="online"
                  legacyField
                  selectProps={{
                    labelClassName: 'text-xs text-gray-400',
                    wrapperClassName:
                      'w-full md:w-[48%] inline-block align-top',
                    errorClassName: 'bg-mandarin-50 shadow-mandarin-50',
                    inputOnErrorClassName: 'shadow-mandarin-50',
                  }}
                  exp={isFreeTrialExperimentActive}
                  setFreeTrialExperimentSelectValue={
                    setFreeTrialExperimentSelectValue
                  }
                />
              </div>
              {isPlus && (
                <div
                  className="p-4 rounded-lg mt-5 sm:mt-7 mb-9 sm:mb-10"
                  style={{
                    background:
                      'linear-gradient(87deg, rgba(255, 255, 255, 0.10) -0.82%, rgba(255, 255, 255, 0.05) 100%)',
                  }}
                >
                  <RadioGroup
                    key="is_agency"
                    id="is_agency"
                    inline={true}
                    name="is_agency"
                    label={t('plus:forms.contact.isAgency.heading')}
                    labelClassName="text-t7 bold"
                    className="[&_input]:w-5 [&_input]:h-5 transition-all cursor-pointer rounded-full checked:border-4 appearance-none default:checked:border-lime-30 active:checked:border-lime-70 hover:checked:border-lime-50 [&_input]:bg-white [&_input:checked]:bg-black"
                    value="false"
                    options={[
                      {
                        id: 'isAgency',
                        label: t('plus:forms.contact.isAgency.optionYes.label'),
                        value: 'true',
                      },
                      {
                        id: 'isNotAgency',
                        label: t('plus:forms.contact.isAgency.optionNo.label'),
                        value: 'false',
                        checked: true,
                      },
                    ]}
                  />
                </div>
              )}
              <Input
                className="hidden"
                id="address"
                key="address"
                name="forms_contact_address"
                type="text"
                value="example"
              />
              <input
                type="hidden"
                name="ld_bookit_log_id"
                id="ld_bookit_log_id"
              />
              {Object.keys(trackingData).map((key) => (
                <input
                  type="hidden"
                  name={key}
                  key={key}
                  value={trackingData[key]}
                />
              ))}
              <div className="flex flex-end">
                <FormButton
                  type="submit"
                  className="grow md:grow-0"
                  componentName="contact-form-submit"
                  mode="dark"
                  aria-describedby="contact-disclaimerText"
                  disabled={isSubmitting}
                >
                  {isSubmitting
                    ? t('plus:contactCustomizations.form.button_submitting')
                    : t('plus:contactCustomizations.form.button')}
                </FormButton>
              </div>
            </ValidatedForm>
            <div
              id="contact-disclaimerText"
              className="border-b border-b-[#71717A]"
            >
              <Typography className="text-sm mt-7 sm:mt-sm mb-xl">
                {t('plus:forms.disclaimer')}
              </Typography>
            </div>

            <div className="py-7 sm:py-10">
              <h2 className="text-lg sm:text-xl font-medium mb-7 sm:mb-6">
                {t('plus:contactCustomizations.chat.heading')}
              </h2>
              <button
                className={`drift-open-chat !text-[#fff] !border-white
                  ${buttonStyles({
                    mode: 'dark',
                    intent: 'secondary',
                    border: true,
                  })}`}
                data-interaction-id={driftInteractionId}
              >
                {t('plus:contactCustomizations.chat.button')}
              </button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}
